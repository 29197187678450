import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './screens/fg/id/landingPageFG/landingPage';
import MembersLandingPage from './screens/members/id/landingPage/landingPage';
import LandingPageEn from './screens/fg/en/landingPageFG/landingPage';
import MembersLandingPageEn from './screens/members/en/landingPage/landingPage';
import KarirPage from './screens/members/id/karir/karir';
import CareerPage from './screens/members/en/careers/career';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/:namapartner" element={<MembersLandingPage/>} />
        <Route path="/en" element={<LandingPageEn/>} />
        <Route path="/:namapartner/en" element={<MembersLandingPageEn/>} />
        <Route path="/:namapartner/karir" element={<KarirPage/>} />
        <Route path="/:namapartner/career" element={<CareerPage/>} />
        {/* <Route path="/partner/:namapartner" component={PartnerDetail} /> */}
      </Routes>
    </Router>
  );
}

export default App;
