// Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import './header.css';
import ReactCountryFlag from "react-country-flag"

const HeaderEn = ({ logoUrl, namapartner }) => {
  return (
    <Navbar bg="light" variant="light" expand="lg" collapseOnSelect>
      <Container>
        <Navbar.Brand href="#home">
          <img src={logoUrl} height={30} className="d-inline-block align-top" alt='logo-fitek' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
          <button style={{ background: "transparent", border: "none" }}onClick={() => {window.location.href = `/${encodeURIComponent(namapartner)}`}}><ReactCountryFlag countryCode="ID" svg /></button>
            <button style={{ background: "transparent", border: "none" }}onClick={() => {window.location.href = `/${encodeURIComponent(namapartner)}/en`}}><ReactCountryFlag countryCode="GB" svg /></button>
            <Nav.Link as={NavLink} to='/' exact>Home</Nav.Link>
            <Nav.Link href="#portofolio">Portfolio</Nav.Link>
            <Nav.Link href="#testimonials">Testimonies</Nav.Link>
            <Nav.Link href="#news">News</Nav.Link>
            <Nav.Link as={NavLink} to= {`/${encodeURIComponent(namapartner)}/career`} exact>Career</Nav.Link>
            <Nav.Link as={NavLink} to='https://cmsfg.fitekservice.my.id/login' exact>Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderEn;
