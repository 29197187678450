import React, { useEffect, useState } from 'react'
import '../../../../screens/members/id/landingPage/landingPage.css'
import Header from '../../../../components/header/id/header'
import { Row, Col, Carousel, Image, Button } from 'react-bootstrap'
import { LoremIpsum } from 'react-lorem-ipsum';
import Collapsible from 'react-collapsible';
import FooterFGMembers from '../../../../components/footer/id/footer-fga-members';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FGMembersCard from '../../../../components/cards/id/fg-members-card';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-scroll';
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from 'react-whatsapp';
import TestimonyCard from '../../../../components/cards/id/testimony-card';
import { FaWhatsapp } from 'react-icons/fa';
import ReadMoreButton from "@erexstudio/react-read-more-text";
import ReadMoreAndLess from "read-more-and-less";
const api = axios.create({
    baseURL: `https://cmsfg.fitekservice.my.id/api`
})
const MembersLandingPage = () => {
    const [result, setResult] = useState([]);
    const [resultAboutUs, setResultAboutUs] = useState([]);
    const [resultArticles, setResultArticles] = useState([]);
    const [resultTestimonies, setResultTestimonies] = useState([]);
    const [resultPorto, setResultPorto] = useState([]);
    const [resultContactUs, setResultContactUs] = useState([]);
    const [resultFaq, setResultFaq] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const { namapartner } = useParams();
    const styles = {
        primaryColor: `${result.primary_color}`,
        secondaryColor: `${result.secondary_color}`,
        // ... other styles
    };
    const itemStyle = {
        width: '413px', // Atur lebar
        height: '413px', // Atur tinggi
    };
    const [isHovered, setIsHovered] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [hoveredImage, setHoveredImage] = useState(null);
    const [hoveredTitle, setHoveredTitle] = useState(null);
    const [hoveredCat, setHoveredCat] = useState(null);
    const [hoveredText, setHoveredText] = useState(null);
    const handleMouseEnter = (index, image, title, cat, text) => {
        setIsHovered(true);
        setHoverIndex(index);
        setHoveredImage(image);
        setHoveredTitle(title);
        setHoveredCat(cat);
        setHoveredText(text);
        console.log('hover triggered, index: ' + index);
    };

    const handleMouseLeave = (index) => {
        setIsHovered(false);
        setHoverIndex(index);
        setHoveredImage(null);
        setHoveredTitle(null);
        setHoveredText(null);
        setHoveredCat(null);
        console.log('mouse leave, index: ' + index);
    };

    const showFullDescriptionHandler = () => {
        setShowFullDescription(!showFullDescription);
    };
    const buttonStyles = {
        color: "blue", // Customize the button color
        // Add any other CSS styles as needed
        background: "transparent",
        border: "none"
    };
    const customColStyle = {
       
    };
    useEffect(() => {
        {
            isHovered && (
                <div className="text-overlay">
                    <p className='poppins-medium'>Ini adalah teks yang ditampilkan saat gambar di-hover.</p>
                </div>
            )
        }
        // Fungsi untuk memanggil API
        const fetchData = async () => {
            try {
                const response = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel= ' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResult(response.data.data); // Mengatur hasil response ke state
                console.log(response.data);

                const responseAboutUs = await api.get('about-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultAboutUs(responseAboutUs.data.data); // Mengatur hasil response ke state
                console.log(responseAboutUs.data);

                const responseArticles = await api.get('articles?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultArticles(responseArticles.data.data); // Mengatur hasil response ke state
                console.log(responseArticles.data);

                const responseTestimonies = await api.get('testimonies?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultTestimonies(responseTestimonies.data.data); // Mengatur hasil response ke state
                console.log(responseTestimonies.data);

                const responsePorto = await api.get('products?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultPorto(responsePorto.data.data); // Mengatur hasil response ke state
                console.log(responsePorto.data);

                const responseContactUs = await api.get('contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultContactUs(responseContactUs.data.data); // Mengatur hasil response ke state
                console.log(responseContactUs.data);

                const responseFaq = await api.get('faq?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultFaq(responseFaq.data.data); // Mengatur hasil response ke state
                console.log(responseFaq.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Panggil fungsi fetch saat komponen di-mount
        fetchData();
        window.scrollTo(0, 0)
    }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount



    return (
        <div>
            <Header logoUrl={`https://cmsfg.fitekservice.my.id/assets/images/aboutUs/${resultAboutUs.logo}`} namapartner={namapartner} />
            <div className='image-banner img-fluid'>
                {/* <img src='/assets/banner.png' alt='banner' width={1519} height={720} /> */}
                <Image src={`https://cmsfg.fitekservice.my.id/assets/images/partner/${result.file}`} alt='banner' width={1519} height={720} fluid />
            </div>
            <div className='banner-text poppins-bold' >
                {/* <h1>
                    Bersama Fhadira Group <br /> Membangun Negeri
                </h1> */}
                <h1>
                    {`${result.name}`}
                </h1>
            </div>
            <div className='about-us'>
                <Row>
                    <Col md={6}>
                        <Image src={`https://cmsfg.fitekservice.my.id/assets/images/aboutUs/${resultAboutUs.pict}`} alt='about-us' className='img-fluid' width={410} height={410} fluid />
                    </Col>
                    <Col md={6}>
                        <div className='about-us-text'>
                            <h1 className='poppins-bold' >Tentang Kami</h1>
                            <p className='poppins-medium'>
                                <div dangerouslySetInnerHTML={{__html: resultAboutUs.description}}/>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='strength'>
                <div className='banner-keunggulan'>
                    {/* <Image src='/assets/banner2.png' alt='banner-keunggulan' width={1519} height={520} fluid /> */}
                    <Image src={`https://cmsfg.fitekservice.my.id/assets/images/aboutUs/${resultAboutUs.banner_2}`} alt='banner-keunggulan' width={1519} height={520} fluid />
                </div>

                <div className='row-container'>
                    <div className='white-box child'>
                        <div className='text-overlay-black'>
                            <h1 className='poppins-bold'>Keunggulan</h1>
                            <p className='poppins-medium'>
                                <div dangerouslySetInnerHTML={{__html: resultAboutUs.featured}}/>
                                </p>
                        </div>
                    </div>
                    <div className='banner-keunggulan-text child'>
                        <h1 className='poppins-bold'>
                            Bersama Fhadira <br /> Group Membangun <br /> Negeri
                        </h1>
                    </div>
                    <div className='child white-box2'>
                        <div className='text-overlay-black'>
                            <h1>{resultAboutUs.total_project}</h1>
                            <p className='poppins-medium'>Client Senang</p>
                        </div>
                    </div>
                    <div className='child green-box'>
                        <div className='text-overlay'>
                            <h1 className='poppins-bold'>
                                {resultAboutUs.total_client}
                                </h1>
                            <p className='poppins-medium'>Jumlah Client</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='portofolio' id='portofolio'>
                <div className='portofolio-title'>
                    <h1 className='poppins-bold'>
                        Portofolio
                    </h1>
                </div>
                <div className='carousel' >
                    <div className='carousel-container'>
                    <OwlCarousel className='owl-theme' loop margin={6} nav responsiveClass responsive={
                        {
                            0: {
                                items: Math.min(1, resultPorto.length)
                            },
                            600: {
                                items: Math.min(2, resultPorto.length)
                            },
                            1000: {
                                items: Math.min(3, resultPorto.length)
                            }
                        }

                    }>
                        {
                            resultPorto.map((porto, index) => (
                                <div
                                    className='item porto-item'
                                    onMouseEnter={() => handleMouseEnter(index, `https://cmsfg.fitekservice.my.id/assets/images/products/${porto.file}`, porto.name, `${porto.category.name}, ${porto.sub_category.name}`, porto.description)}
                                    onMouseLeave={() => handleMouseLeave(null)}
                                    style={itemStyle} 
                                >
                                    <img
                                        src={`https://cmsfg.fitekservice.my.id/assets/images/products/${porto.file}`}
                                        width={413}
                                        height={413}
                                        style={{ objectFit: 'fill' }}
                                        alt='porto-carousel'
                                    />

                                </div>  
                            ))
                        }
                    </OwlCarousel>
                    </div>
                    <div className="popup-image">
                    {hoveredImage && (
                          <div className="justify-content-center" style={{ display: "flex", transitionDelay:"1s" }}>
                          <div className="col">
                              <img src={hoveredImage} alt="popup-image" style={{ width: "800px", height: "400px" }} />
                          </div>
                          <div className="col porto-desc" style={{ '--bg-color': styles.primaryColor  }}>
                          <h1 className='poppins-bold'>{hoveredTitle}</h1>
                                      <p className='poppins-medium'><b>{hoveredCat}</b></p>
                                      <div className='poppins-medium'dangerouslySetInnerHTML={{__html: hoveredText}}></div>
                          </div>
                      </div>
                    )}
                  

                </div>
                </div>
                


            </div>
            <div className='testimoni' id='testimonials'>
            <div>
                        <div className='testimoni-title'>
                            <h1 className='poppins-bold'>
                                Testimoni
                            </h1>
                        </div>
                        <div className='carousel-testimoni' style={{ justifyContent: 'center', justifySelf: "center", alignItems: 'center', alignContent: 'center', padding:"10px" }}>
    <OwlCarousel className='owl-theme' loop nav center responsiveClass responsive={
        {
            0: {
                items: Math.min(1, resultTestimonies.length)
            },
            600: {
                items: Math.min(2, resultTestimonies.length)
            },
            1000: {
                items: Math.min(3, resultTestimonies.length)
            }
        }
    }>
        {
            resultTestimonies.map((testimony, index) => (
                <div key={index} className='item' >
                    <TestimonyCard imageSrc={`https://cmsfg.fitekservice.my.id/assets/images/testimony/${testimony.file}`} alt='logo' title={`${testimony.name}`} text={`${testimony.description}`} />
                </div>
            ))
        }
    </OwlCarousel>
</div>


                    </div>
            </div>

            {resultAboutUs.visi && resultAboutUs.misi && (
                <div class='visi-misi' style={{ '--bg-color': styles.primaryColor }}>
                    <div class='row'>
                        <div class='visi'>
                            <h1 className='poppins-bold'>Visi</h1>
                            <p className='poppins-medium'>
                                <div dangerouslySetInnerHTML={{__html: resultAboutUs.visi}}/>
                                </p>
                        </div>
                        <div class='misi'>
                            <h1 className='poppins-bold'>Misi</h1>
                            <p className='poppins-medium'>
                                <div dangerouslySetInnerHTML={{__html: resultAboutUs.misi}}/>
                                </p>
                        </div>
                    </div>
                </div>
            )}
            {resultArticles.name && (
                <div className='news' id='news'>
                    <div className='news-title'>
                        <h1 className='poppins-bold'>
                            Berita
                        </h1>
                    </div>
                    <div className='news-item' >
                        {
                            resultArticles.map(articles => (
                                <div className='news-item-card'>
                                    <img src={`https://cmsfg.fitekservice.my.id/assets/images/news/${articles.file}`} alt='news-item-img' width={400} height={400} />
                                    <h1 className='poppins-bold'>{`${articles}`}</h1>
                                    <p className='poppins-medium'>Baca Selengkapnya</p>
                                </div>
                            ))
                        }
                        {/* <div className='news-item-card'>
                        <img src='/assets/carousel-item2.png' alt='news-item-img' width={400} height={400} />
                        <h1>PT Fhadira Inovasi Teknologi</h1>
                        <p>Baca Selengkapnya</p>
                    </div>
                    <div className='news-item-card'>
                        <img src='/assets/carousel-item2.png' alt='news-item-img' width={400} height={400} />
                        <h1>PT Fhadira Inovasi Teknologi</h1>
                        <p>Baca Selengkapnya</p>
                    </div>
                    <div className='news-item-card'>
                        <img src='/assets/carousel-item2.png' alt='news-item-img' width={400} height={400} />
                        <h1>PT Fhadira Inovasi Teknologi</h1>
                        <p>Baca Selengkapnya</p>
                    </div> */}

                    </div>

                </div>)}

            <div className='faq'>
                <div className='faq-title'>
                    <h1 style={{ textAlign: "center"  }} className='poppins-bold'>FAQ</h1>
                </div>
                {
                    resultFaq.map(faq =>(
                        <Collapsible trigger={faq.question}>
                    <p className='poppins-medium'>
                        {faq.answer}
                    </p>
                </Collapsible>
                    ))
                }
                {/* <Collapsible trigger="Start here">
                    <p>
                        This is the collapsible content. It can be any element or React
                        component you like.
                    </p>
                    <p>
                        It can even be another Collapsible component. Check out the next
                        section!
                    </p>
                </Collapsible>
                <Collapsible trigger="Start here">
                    <p>
                        This is the collapsible content. It can be any element or React
                        component you like.
                    </p>
                    <p>
                        It can even be another Collapsible component. Check out the next
                        section!
                    </p>
                </Collapsible> */}

            </div>
            <ScrollToTop smooth />

            <div className='footer' style={{ backgroundColor: styles.primaryColor, position: 'relative' }} >

            <a href={`https://web.whatsapp.com/send?phone=${resultContactUs.mobile_phone}`} variant='success' className='waButton' style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '9999', backgroundColor: 'green', borderRadius: '50%', width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight:"100px" }} id='whatsapp'>
                    <FaWhatsapp size={40} color='white'/>
                </a>
                <FooterFGMembers backgroundColor={styles.primaryColor} twitter={resultContactUs.twitter} instagram={resultContactUs.instagram} linkedin={resultContactUs.linkedln} email={resultContactUs.email}/>
            </div>

        </div>
    )
}

export default MembersLandingPage