// Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import './header.css';
import ReactCountryFlag from "react-country-flag"

const Header = ({ logoUrl, namapartner }) => {
  return (
    <Navbar bg="light" variant="light" expand="lg" collapseOnSelect>
      <Container>
        <Navbar.Brand href="#home">
          <img src={logoUrl} height={30} className="d-inline-block align-top" alt='logo-fitek' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <button style={{ background: "transparent", border: "none" }}onClick={() => {window.location.href = `/${encodeURIComponent(namapartner)}`}}><ReactCountryFlag countryCode="ID" svg /></button>
            <button style={{ background: "transparent", border: "none" }}onClick={() => {window.location.href = `/${encodeURIComponent(namapartner)}/en`}}><ReactCountryFlag countryCode="GB" svg /></button>
            <Nav.Link as={NavLink} to='/' exact className='poppins-medium'>Home</Nav.Link>
            <Nav.Link href="#portofolio" className='poppins-medium'>Portofolio</Nav.Link>
            <Nav.Link href="#testimonials" className='poppins-medium'>Testimoni</Nav.Link>
            <Nav.Link href="#news" className='poppins-medium'>Berita</Nav.Link>
            <Nav.Link as={NavLink} to= {`/${encodeURIComponent(namapartner)}/karir`} exact className='poppins-medium'>Karir</Nav.Link>
            <Nav.Link as={NavLink} to='https://cmsfg.fitekservice.my.id/login' exact className='poppins-medium'>Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
