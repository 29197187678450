import React from 'react';
import { Card } from 'react-bootstrap';
import './fg-members-card.css';
import { Link } from 'react-router-dom';

const TestimonyCard = ({ imageSrc, title, text }) => {
  return (
    <Card border='light' className="fga-member-card">
      <Card.Img src={imageSrc} width={400} height={200} />
      <Card.Body>
        <Card.Title className='fga-member-card-title'>
          {title}
        </Card.Title>
        <Card.Text className='fga-member-card-text'>
          {text}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TestimonyCard;
