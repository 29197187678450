import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Footer = ({phone, email, address}) => {
  return (
    <footer className="bg-dark text-white mt-5 p-5">
      <Container>
        <Row>
          <Col md={6} style={{textAlign:'center'}}>
            <h5 >Informasi Kontak</h5>
            <p><FaPhone/> {`${phone}`}</p>
            <p><FaEnvelope/> {`${email}`}</p>
            <p><FaMapMarkerAlt/> {`${address}`}</p>    
          </Col>
          <Col md={6} className="text-center">
            <Image src='/assets/map.png' alt='Peta Lokasi Fhadira Group' style={{ width: '400px', height: 'auto' }} fluid/>
          </Col>
        </Row>
      </Container>
      <div className="text-center mt-5">
        <p>&copy; 2024 Fhadira Group. Hak Cipta Dilindungi.</p>
      </div>
    </footer>
  );
};

export default Footer;
